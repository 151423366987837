import React, { useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import classNames from 'classnames';

function ReviewDeck({
  title = 'Read what other customers say about their experience',
  testimonials = [],
  greyBg = false,
  variant,
}) {
  const swiperRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <section
      id="review-deck"
      className={classNames('pt-5 pb-5 pt-md-10 pb-md-10', {
        'section-pale': greyBg,
        [variant]: true,
      })}>
      <div className="container">
        <div className="d-block d-md-flex justify-content-between mb-3">
          <h6 className="text-phone text-grey pb-5">{title}</h6>

          <div className="d-none d-md-flex swiper-arrows-wrapper">
            <div
              className="swiper-button-prev"
              ref={navigationPrevRef}
              style={{ top: '-6px' }}
            />
            <div
              className="swiper-button-next ml-2"
              ref={navigationNextRef}
              style={{ top: '-6px' }}
            />
          </div>
        </div>

        <div className="review-deck">
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onInit={(swiper) => {
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={1}
            pagination={{ clickable: true }}
            breakpoints={{
              700: {
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 3,
                pagination: false,
              },
            }}>
            {testimonials.map(({ text, name, date }, index) => (
              <SwiperSlide key={index}>
                <ReviewCard text={text} name={name} date={date} greyBg={greyBg} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

function ReviewCard({ text, name, rating = 5, date, greyBg }) {
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);

  const truncatedText = text.length > 250 ? `${text.substring(0, 250)}...` : text;
  const reviewText = isReadMoreOpen ? text : truncatedText;

  const handleReadMore = () => {
    setIsReadMoreOpen((prev) => !prev);
  };

  return (
    <div
      className={classNames('container-white-border review-card', {
        'no-box-shadow': greyBg,
      })}>
      <div className="d-flex justify-content-between mb-4">
        <p className="review-name">{name}</p>
        <div className="review-rating-stars">
          {Array.from({ length: rating }).map((_, index) => (
            <i key={index} className="fas fa-star" />
          ))}
          <span className="review-rating-score">{rating && rating.toFixed(1)}</span>
        </div>
      </div>
      <p className="review-text">
        {reviewText}
        {text.length > 250 && (
          <span className="link" onClick={handleReadMore}>
            {' '}
            {isReadMoreOpen ? 'Read less' : 'Read more'}
          </span>
        )}
      </p>
      <div className="d-flex justify-content-between mt-4">
        <span className="review-date">{date}</span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/maps/place/Pandev+Law/@32.779684,-79.9334468,17z/data=!4m7!3m6!1s0x88fe7a121d60cfc1:0x5e811a74d5fdf2f3!8m2!3d32.7796795!4d-79.9312581!9m1!1b1?hl=en-US">
          <StaticImage
            className="lazy"
            width={80}
            quality={100}
            placeholder="blurred"
            layout="fixed"
            src="../../assets/img/google-logo.png"
            alt="Google 5-star rating"
          />
        </a>
      </div>
    </div>
  );
}

ReviewDeck.propTypes = {
  title: PropTypes.string,
  testimonials: PropTypes.array,
  greyBg: PropTypes.bool,
  variant: PropTypes.string,
};

export default ReviewDeck;
