import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { HOME_TESTIMONIALS } from '../../data/home/testimonials';
import FeaturedIn from '../common/featured-in';
import ReviewDeck from '../common/review-deck';
import Testimonials from '../common/testimonials';
import { AB_TESTING } from '../../data/site-data';

function AbTestingDeck({
  position = 'bottom',
  title = 'Read what our clients say about their experience',
  testimonials = HOME_TESTIMONIALS,
  greyBg = true,
}) {
  if (position === 'top') {
    return <ReviewDeck title={title} testimonials={testimonials} greyBg={greyBg} />;
  }

  return <FeaturedIn greyBg={greyBg} variant={AB_TESTING.variant1} />;
}

AbTestingDeck.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
  title: PropTypes.string,
  testimonials: PropTypes.array,
  greyBg: PropTypes.bool,
};

export default AbTestingDeck;
