import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const API_KEY = 'AIzaSyBUhPSZnGmfn-_BJSpNAmFWc2d-tW8r9xI';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const styles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#F7F7F7',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8B8B8B',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#eceff2',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#EDEDED',
      },
    ],
  },
];

function OfficeMeta({
  workingHours = [
    {
      day: 'Monday',
      schedule: '8:30AM–5:00PM',
    },
    {
      day: 'Tuesday',
      schedule: '8:30AM–5:00PM',
    },
    {
      day: 'Wednesday',
      schedule: '8:30AM–5:00PM',
    },
    {
      day: 'Thursday',
      schedule: '8:30AM–5:00PM',
    },
    {
      day: 'Friday',
      schedule: '8:30AM–5:00PM',
    },
    {
      day: 'Saturday',
      schedule: 'Closed',
    },
    {
      day: 'Sunday',
      schedule: 'Closed',
    },
  ],
  googleBusinessLink,
  googleReviewsCount,
  googleRating = 5.0,
  officeTitle,
  addressLine1,
  addressLine2,
  telephone,
  displayTelephone,
  mapCenter = {
    lat: 0,
    lng: 0,
  },
}) {
  return (
    <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 pl-lg-6 mb-5 mb-lg-0 order-lg-12">
            <LoadScript googleMapsApiKey={API_KEY}>
              <GoogleMap
                options={{
                  styles,
                }}
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={15}>
                <Marker position={mapCenter} />
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="col-lg-5 order-lg-1 d-flex align-items-center">
            <div className="container-grey-border">
              <h3 className="text-headline mb-5">{officeTitle}</h3>
              <p className="text-lead">{addressLine1}</p>
              <p className="text-lead">{addressLine2}</p>
              <p className="text-lead">
                <a
                  className="link-phone-alt phone-new-york-midtown"
                  href={`tel:${telephone}`}>
                  {displayTelephone}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-6 mt-lg-12">
          <div className="offset-lg-1 col-lg-5">
            <h3 className="text-headline mb-6">Working hours:</h3>
            {workingHours.map(({ day, schedule }, index) => (
              <div
                className="d-flex justify-content-between align-items-center"
                key={index}>
                <div className="flex-1">
                  <p>{day}</p>
                </div>
                <div className="flex-1">
                  <p>
                    <strong>{schedule}</strong>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            <h3 className="text-headline mb-7">Ratings:</h3>
            <div className="container-grey-border d-flex align-items-center">
              <div className="text-center mr-3 mr-md-7">
                <p className="text-hero">{googleRating.toFixed(1)}</p>
                <div className="container-raiting">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
              </div>
              <div>
                <a
                  href={googleBusinessLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-headline link-primary">
                  {googleReviewsCount} Google reviews
                </a>
                <p className="text-body">Immigration attorney in New York</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

OfficeMeta.propTypes = {
  workingHours: PropTypes.array,
  googleReviewsCount: PropTypes.number.isRequired,
  googleRating: PropTypes.number.isRequired,
  mapCenter: PropTypes.object.isRequired,
};

export default OfficeMeta;
