export const CHARLESTON_TESTIMONIALS = [
	{
		text: 'Used the services offered by Pandev Law to start a limited liability company and the entire experience was seamless to say the least. Very quick follow-ups from the team and they happily answer any and all questions you ask them with a perfect amount of depth. Would highly recommend them for any of your legal needs!',
		name: 'Karan Narang',
		stars: 5,
	},
	{
		text: 'The Pandev Law firm made it possible for me to obtain my work visa. They were transparent and efficient in their communication and execution  of the process. Based on their experience and efficiency they will prepare for any issues they might foresee so you will not be surprised if you encounter a delay or problem. You can always trust their advise when you have to make a decision about your case, the options are laid out for you to choose what best fits your situation.  Everything  is done transparently. Pandev Immigration you rock!',
		name: 'Helen Faulknor-Pugh',
		stars: 5,
	},
	{
		text: 'The  lawyers at Pandev Law are absolutely awesome. They worked meticulously to help me and my family as we adjusted our status from the J1 to the H1 visa. My case was handled professionally but most of all they cared.  They have a remarkable system of communicating with me about my case and the  response time was always prompt. I was never left in the dark  because I was clearly told what the next steps would be. I cannot say enough about this team but thank you for having me wake up to the positive emails. Highly recommended law firm.',
		name: 'Marlene Golding',
		stars: 5,
	},
	{
		text: 'Adrian was successful at obtaining my E-2 visa. He not only guided us through the immigration aspect but also helped us with contract drafting and deal structuring to ensure a successful visa application. Pandev Law is a top business and immigration law firm!',
		name: 'Kristian Kuharszky',
		stars: 5,
	},
	{
		text: 'Being an F1- international student, It was intimidating for me to go through the process of legal work done to form my LLC. Adrian and his team consistently provided my digital startup company with amazing representation, from company formation to drafting various critical contracts and agreements. Adrian also provided me with important advice on international deals and U.S. immigration compliance. He took away the stress of legal work off my head and asked me to focus on my business which is exactly what everyone seeks from an attorney.Adrian is always a pleasure to work with and truly cares about your business as he does about your legal issues. He is like close friend who makes you very comfortable and explains you everything in great detail. I would highly recommend him to foreign individuals seeking any kind of legal advice or services.',
		name: 'Anshul Augnivansh',
		stars: 5,
	},
	{
		text: 'Adrian provided me and my husband the best professional and compassionate service of any attorney we have ever had. He is LGBT friendly and would be the only immigration attorney I recommend to combat today’s difficult policies. We had a quick green card approval through his hard work and dedication. This man actually cares about his clients. Thank you Adrian!!!',
		name: 'Daniel Levine',
		stars: 5,
	},
	{
		text: "I cannot say enough good things about Adrian and his team. The green card process can be a very stressful one, and you constantly feel on edge because you want to make sure every step is done correctly. Pandev Law takes the guesswork out of everything. They guided us through every step, answered every question we had and made sure we were 100% prepared for our interview. And because of them, my husband was approved for his green card! They truly had our best interest at heart and took incredible care of us. If you're looking for an immigration lawyer, look no further. Adrian and his team are absolutely amazing! Thank you guys, so much! : )",
		name: 'Amanda Darchiville',
		stars: 5,
	},
	{
		text: 'I can’t thank Adrian and his team enough! I reached out to Adrian for a 30 mins consultation regarding my case, and just like that, in a few months I got my family based green card 🙏🏽🙏🏽My family and I are so happy and grateful we made the right choice to use Pandev Law. Highly recommend Adrian, he was very thorough and generous. 5 stars is just not enough 🙏🏽',
		name: 'Andrine Campbell',
		stars: 5,
	},
	{
		text: 'Adrian and his team of lawyers helped me to obtain my H1B visa. They are knowledgeable, thorough, reliable, and respectful in the execution of their duties. They responded to every email that I sent them in a timely manner. Their responses were always positive and reassuring. I would recommend Adrian and his team of lawyers to anyone who has an immigration matter to solve. I really love the way they made me feel valuable and respected. Keep up the good job !!!',
		name: 'Arlene Henry',
		stars: 5,
	},
	{
		text: 'I had a consultation with Adrian Pandev, and it was a fantastic experience. I found him very knowledgeable, beneficial, and honest. The most eye-catching characteristic of his consulting is that Adrian gave me a 360 exploration of the problem, a holistic view of my case, and a package of recommendations that was directly related to my case and helped me to avoid unnecessary actions. I will choose Pandev Law again and recommend them to others.',
		name: 'Ali Ghiasabadi',
		stars: 5,
	},
	{
		text: 'Prior to booking Mr. Pandev I felt very discouraged.  However, once he reviewed my documents I felt a since of relieved.  He corrected my documents as well as patiently answers my questions while helping me to feel confident again.  To make a long story short he exceeded my expectations to the highest.  With that being said, my husband will be here in the next week. I  would suggest for anyone going through the immigration process to definitely choose Mr. Pandev.',
		name: 'Juanita Odom',
		stars: 5,
	},
];
