import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import CtaDeck from '../components/common/cta-deck';
import { CHARLESTON_PHONE, CHARLESTON_PHONE_DISPLAY } from '../data/site-data';
import OfficeMeta from '../components/contact/office-meta';
import { CHARLESTON_TESTIMONIALS } from '../data/charleston/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';

const headMeta = {
  title: 'Charleston Immigration Lawyer Near Me | Pandev Law | City in South Carolina',
  description:
    'Searching for a trusted and experienced Immigration Lawyer near you in Charleston? We help with visas, green cards, naturalization and work permits. Talk to us now!',
  keywords: 'Charleston immigration lawyer,immigration lawyer near me',
};

const schema = `{
  "@context": "http://schema.org",
  "@type": "LegalService",
  "name": "Charleston Immigration Lawyer Near Me - Pandev Law",
  "description": "Searching for a trusted and experienced Immigration Lawyer near you in Charleston? We help with visas, green cards, naturalization and work permits. Talk to us now!",
  "url": "https://www.pandevlaw.com/charleston-immigration-lawyer",
  "image": "https://pandevlaw.com/img/ch-office-inner.jpg",
  "priceRange": "$$",
  "telephone": "${CHARLESTON_PHONE_DISPLAY}",
  "email": "adrian@pandevlaw.com",
  "hasMap": "https://www.google.com/maps/place/Pandev+Law/@32.7796795,-79.9312581,15z/data=!4m5!3m4!1s0x0:0x5e811a74d5fdf2f3!8m2!3d32.7796795!4d-79.9312581",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Charleston",
    "addressRegion": "SC",
    "postalCode": "29401",
    "streetAddress": "170 Meeting Street"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": "32.779923",
    "longitude": "-79.930840"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  },
  "sameAs": [
    "https://en-gb.facebook.com/pandevlaw/"
  ],
  "openingHours": "Mo,Tu,We,Th,Fr, 8:30am-5:00pm"
}`;

function CharlestonOffice({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <StaticImage
        className="img-fluid d-lg-none"
        src="../assets/img/ch-office.jpg"
        alt="Charleston immigration lawyer near me"
      />
      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <StaticImage
                className="img-fluid d-none d-lg-inline-block"
                src="../assets/img/ch-office-inner.jpg"
                alt="Charleston immigration lawyer near me"
                placeholder="none"
                loading="eager"
              />
            </div>
            <div className="col-lg-7 pl-lg-6">
              <h1 className="text-hero mb-5 mb-md-8">Charleston Immigration Lawyer</h1>
              <div className="d-flex mb-5">
                <div>
                  <p className="text-lead text-grey mb-3">Call us at:</p>
                  <a
                    href={`tel:${CHARLESTON_PHONE}`}
                    className="phone-new-york-downtown text-phone cta-green">
                    <i className="fas fa-phone fa-flip-horizontal icon-phone" />
                    {CHARLESTON_PHONE_DISPLAY}
                  </a>
                  <p className="text-lead text-grey mt-3">For a consultation</p>
                </div>
              </div>
              <div className="mb-5">
                <p className="text-mega font-size-24 mb-4">As featured in:</p>
                <div className="d-flex">
                  <a
                    href="https://threebestrated.com/immigration-lawyers-in-charleston-sc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={50}
                      quality={100}
                      src="../assets/img/threebestrated.png"
                      alt="Three best rated - Pandev Law - Immigration Lawyer"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                  <a
                    href="https://www.elitelawyer.com/profile/10897-adrian-pandev"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={50}
                      quality={100}
                      src="../assets/img/elitelawyer.png"
                      alt="Elite lawyer - Pandev Law - Immigration Lawyer"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </div>
              </div>
              <p className="text-body">
                If you are searching for “immigration lawyer near me”, you have come to
                the right place. Pandev Law&#39;s Charleston immigration law office is
                situated in the heart of Charleston&#39;s world famous Historic District.
                Our Charleston immigration lawyer is located just one block south of the
                Historic Charleston City Market, in downtown Charleston. So if you are
                searching for an “immigration attorney near me,” we are conveniently
                located to help you.
                <br />
                <br />
                In recent years, South Carolina has turned into a hotbed for global
                business. Its location between New York, Atlanta, and Miami, its deep
                water ports, and major container shipping terminals have attracted
                numerous international businesses to the state. In 2017, fDi Magazine,
                which provides an up-to-date review of global investment activity, ranked
                South Carolina number one in the US for foreign direct investment. As a
                trusted Charleston immigration law firm, we are well-positioned to
                effectively represent you, or your business, in immigration and
                international business matters.
              </p>
            </div>
          </div>
        </div>
      </div>

      <AbTestingDeck
        position="top"
        title="Charleston Client Reviews"
        testimonials={CHARLESTON_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div className="row mt-5 mt-lg-13">
            <div className="col-lg-6 mb-5 mb-lg-0 order-lg-12">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/charleston-office-outside.jpg"
                alt="Immigration Lawyer Charleston"
                quality={15}
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <p className="text-body">
                Our Charleston immigration lawyer represents individuals and organizations
                in various industries, including education, technology, online
                advertising, healthcare, manufacturing, construction, academia, and
                research labs. Regardless of your particular situation our immigration
                lawyer Charleston is here to assist you with all your immigration needs.
                Your search for “immigration lawyer near me” has brought you to the right
                place. Contact Pandev Law’s Charleston immigration law office if you are
                seeking business or immigration law representation.
                <br />
                <br />
                <strong>Immigration Lawyer Charleston</strong>
                <br />
                Our Charleston immigration law firm focuses on{' '}
                <a href="/investor-visa-usa-immigration">USA investor visa</a>,{' '}
                <a href="/family-based-green-card-immigration">
                  family-based immigration
                </a>
                , and{' '}
                <a href="/employment-based-visa-immigration">
                  employment-based immigration
                </a>
                , particularly <a href="/eb-5-visa">EB-5 Investors</a> and{' '}
                <a href="/e-2-visa">E-2 Treaty Investors</a>, H-1B Specialty Workers, and{' '}
                <a href="/l-1-visa">L-1 Intracompany Transferees</a>. If you are looking
                for an “immigration attorney near me,” we have the experience to help you
                in your immigration case. We also regularly advise startups and
                established organizations regarding visa sponsorship for foreign
                employees. Our immigration lawyer Charleston services include immigration
                compliance, such as Form I-9 and E- Verify compliance. In addition, we
                have extensive experience with the employment-based permanent residence
                process, including PERM Labor Certifications, EB-2 National Interest
                Waivers, and EB-1 petitions for Multinational Managers and Individuals of
                Extraordinary Ability, including academics, researchers, scientists,
                artists, and athletes. If you are searching for a “business immigration
                lawyer near me” or an “employment-based immigration lawyer near me,” we
                have the experience and know- how to help you in your immigration case.
              </p>
            </div>
          </div>
          <div className="row mt-5 mt-lg-13">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/charleston-office-inside.jpg"
                alt="Family-Based Immigration Lawyer Charleston"
              />
            </div>
            <div className="col-lg-6">
              <p className="text-body">
                <strong>Family-Based Immigration Lawyer Charleston</strong>
                <br />
                Are you searching for a “family-based immigration lawyer near me” to help
                you sponsor a visa or green card for a family member? We have extensive
                experience uniting families through marriage, fiance, and other
                family-based immigration petitions.
                <br />
                <br />
                <strong>
                  Teacher, Professor, and Researcher Immigration Lawyer Charleston
                </strong>
                <br />
                Are you a teacher or educational institution seeking an “immigration
                lawyer near me”? We specialize in representing educational institutions,
                including primary schools and colleges, in immigration related matters.
                <br />
                <br />
                <strong>International Business Lawyer Charleston</strong>
                <br />
                Our business law firm focuses on advising entrepreneurs, small, and medium
                sized businesses in formation and governance, mergers and acquisitions,
                commercial transactions, and financing, including venture capital. We have
                extensive experience representing foreign companies entering the US market
                and regularly advise on cross-border transactions. Our Charleston
                international business lawyer advises on import and export compliance and
                helps obtain relief for alleged customs law violations. We represent
                foreign blockchain startups and cryptocurrency investors who wish to move
                their business to the US.
                <br />
                <br />
                Whether you are searching for “immigration lawyer near me,” “international
                business lawyer near me,” or “immigration attorney near me,” you have come
                to the right place. Contact Pandev Law’s Charleston office for all your
                business or immigration law needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <OfficeMeta
        googleReviewsCount={99}
        googleRating={5.0}
        officeTitle="Charleston Immigration Office"
        addressLine1="170 Meeting Street"
        addressLine2="Charleston, SC 29401"
        telephone={CHARLESTON_PHONE}
        displayTelephone={CHARLESTON_PHONE_DISPLAY}
        mapCenter={{
          lat: 32.779917,
          lng: -79.931251,
        }}
        googleBusinessLink="https://www.google.com/maps/place/Pandev+Law/@32.779684,-79.9334468,17z/data=!4m7!3m6!1s0x88fe7a121d60cfc1:0x5e811a74d5fdf2f3!8m2!3d32.7796795!4d-79.9312581!9m1!1b1?hl=en-US"
      />

      <AbTestingDeck position="bottom" />
      <PilarNavigation />
      <CtaDeck />
    </Layout>
  );
}

export default CharlestonOffice;
