import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';
import {
  LINKING_DECK_CORPORATE_LAW_LINKS,
  LINKING_DECK_IMMIGRATION_LAW_LINKS,
} from '../../data/site-data';

function RenderList({ list = [], title }) {
  return (
    <div>
      <h3 className="pilar-category-title mb-5">{title}</h3>
      <ul className="pilar-ul">
        {list.map(({ link, title, isSubLink }, index) => (
          <li
            key={index}
            className={classNames('pilar-category-title-li', {
              'pilar-category-title-li-sub-link': isSubLink,
            })}>
            <Link key={link + title} to={link}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right mr-4"
                viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
              <span className="pilar-category-title-link-text">{title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

function PilarNavigation() {
  return (
    <div className="section mt-8 pt-5 pb-5 pt-md-8">
      <div className="container">
        <h6 className="text-phone text-grey mb-8">
          Pandev Law provides the following legal services
        </h6>
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8">
            <div className="row">
              <div className="col-12 col-sm-6 mb-6">
                <RenderList
                  list={LINKING_DECK_CORPORATE_LAW_LINKS}
                  title="Corporate Law"
                />
              </div>
              <div className="col-12 col-sm-6 d-sm-flex justify-content-end">
                <RenderList
                  list={LINKING_DECK_IMMIGRATION_LAW_LINKS}
                  title="Immigration Law"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PilarNavigation;
