import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import SwiperCore from 'swiper';
import { Autoplay } from 'swiper/modules';
import { AB_TESTING } from '../../data/site-data';

SwiperCore.use([Autoplay]);

function FeaturedIn({ greyBg = false, variant = AB_TESTING.original }) {
  const swiperSettings = {
    slidesPerView: 5,
    freeMode: false,
    allowTouchMove: false,
    autoplay: {
      delay: 2500,
      enabled: true,
    },
    breakpoints: {
      991: {
        slidesPerView: 5,
        allowTouchMove: false,
        autoplay: { enabled: false },
      },
      768: {
        slidesPerView: 3,
        allowTouchMove: true,
      },
      500: {
        allowTouchMove: true,
        slidesPerView: 2,
      },
      200: {
        allowTouchMove: true,
        slidesPerView: 1,
      },
    },
  };

  return (
    <div
      className={classNames('section', {
        'section-pale my-5 pt-5 pb-5': greyBg,
        [variant]: true,
      })}>
      <div className="container">
        <div className="mt-9 mt-md-10 d-flex flex-wrap justify-content-center justify-content-lg-between align-items-center">
          <div className="col-12 px-0 mb-5">
            <h6 className="text-phone text-grey">As featured in</h6>
          </div>

          <div className="d-flex w-100" />
          <Swiper className="swiper-as-seen-as-logos d-flex w-100" {...swiperSettings}>
            <div className="swiper-wrapper">
              <SwiperSlide className="mb-4 mb-lg-0 pl-3 pr-3 pl-lg-0 pr-lg-0 order-lg-0">
                <a
                  href="https://profiles.superlawyers.com/new-york-metro/new-york/lawyer/adrian-pandev/04411de3-60c5-4ace-aa12-e98485d9be2b.html"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StaticImage
                    className="lazy"
                    height={80}
                    placeholder="tracedSVG"
                    src="../../assets/img/superlaweyrs.png"
                    alt="Best Lawyers - Pandev Law - Immigration Lawyer"
                  />
                </a>
              </SwiperSlide>
              <SwiperSlide className="mb-4 mb-lg-0 pl-3 pr-3 pl-lg-0 pr-lg-0 order-lg-1">
                <a
                  href="https://abcnews4.com/newsletter/despite-mcmaster-remark-dss-no-cap-on-foster-kids-but-system-is-capacity-strained"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StaticImage
                    className="lazy"
                    height={90}
                    width={90}
                    placeholder="tracedSVG"
                    src="../../assets/img/abc.png"
                    alt="ABC"
                  />
                </a>
              </SwiperSlide>
              <SwiperSlide className="mb-4 mb-lg-0 pl-3 pr-3 pl-lg-0 pr-lg-0 order-lg-1">
                <a
                  href="https://kttc.marketminute.com/article/abnewswire-2022-6-28-charleston-business-and-immigration-law-firm-welcomes-2022-summer-associate"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StaticImage
                    className="lazy"
                    width={100}
                    height={90}
                    placeholder="tracedSVG"
                    src="../../assets/img/nbc.png"
                    alt="NBC"
                  />
                </a>
              </SwiperSlide>
              <SwiperSlide className="mb-4 mb-lg-0 pl-3 pr-3 pl-lg-0 pr-lg-0 order-lg-1">
                <a
                  href="https://www.ny1.com/nyc/all-boroughs/politics/2022/11/23/families-spend-hours-in-the-cold-waiting-for-ice-appointments"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StaticImage
                    className="lazy"
                    width={200}
                    placeholder="tracedSVG"
                    src="../../assets/img/ny1.png"
                    alt="NY1 Spectrum News"
                  />
                </a>
              </SwiperSlide>
              <SwiperSlide className="mb-4 mb-lg-0 pl-3 pr-3 pl-lg-0 pr-lg-0 order-lg-1">
                <a
                  href="https://www.benzinga.com/pressreleases/21/04/ab20666598/charleston-immigration-law-firm-adds-prominent-intellectual-property-attorney"
                  target="_blank"
                  rel="noopener noreferrer">
                  <StaticImage
                    className="lazy"
                    width={120}
                    placeholder="tracedSVG"
                    src="../../assets/img/benzinga.png"
                    alt="Benzinga"
                  />
                </a>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>
      <div className="pb-5 pb-md-10" />
    </div>
  );
}

FeaturedIn.propTypes = {
  greyBg: PropTypes.bool,
};

export default FeaturedIn;
